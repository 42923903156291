import React from 'react';

import ProcessStages from '../components/ProcessStages'
import UserDataForm from '../components/UserDataForm'

import './ContactInformation.css'

const stages = ['Kontakinforasjon', 'Bolig og lån', 'Selge andel'];

export default function ContactInformation() {
    return <div>
        <h1>Contact information</h1>
        <ProcessStages stages={stages} currentStage={0} />
        <UserDataForm />
    </div>
}