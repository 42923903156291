import React from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

import ContactInformation from "./pages/ContactInformation";
import House from "./pages/House";
import Application from "./pages/Application";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import "./App.css";

const App = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    
      <div id="app" className="app d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
        <Routes>
          {!isAuthenticated &&
            <>
            <Route path="/" exact element={<ContactInformation />} />
            <Route path="/house" exact element={ <House /> } />
            <Route path="/application" element={ <Application />} />
            </>
          }
          {isAuthenticated && 
            <>
            <Route path="/" exact element={<ContactInformation />} />
            <Route path="/house" exact element={ <House /> } />
            <Route path="/application" element={ <Application />} />
            </>
          }
        </Routes>
        </Container>
        <Footer />
      </div>
  );
};

export default App;
