import React, { useState } from 'react';

import TextInput from './TextInput';

import './UserDataForm.css'

const UserDataForm = () => {
  // State to store input values
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (email && phone && agreed) {
      setSubmitted(true);
      console.log('Form Submitted:', { email, phone });
      window.location = '/house'
    } else {
      alert('Please fill all fields and agree to the rules');
    }
  };

  return (
    <div className="user-form-container">
      <h2>Hei,</h2>
      <h3>Vi trenger litt mer kontaktinformasjon for å behandle søknaden</h3>

      <form onSubmit={handleSubmit}>
        {/* Email Input */}
        <div className="form-group">
          <TextInput
            label="E-post"
            value={email}
            onChange={(val) => setEmail(val)}
          />
        </div>

        {/* Phone Input */}
        <div className="form-group">
          <TextInput
            label="Mobilnummer"
            value={phone}
            onChange={(val) => setPhone(val)}
          />
        </div>

        {/* Agreement Checkbox */}
        <div className="form-group">
          <label htmlFor="agree">
            <input
              type="checkbox"
              id="agree"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              required
            />
            Jeg godtar at HILI kan kontakte meg vedrørende min søknad
          </label>
        </div>

        {/* Agreement Checkbox */}
        <div className="form-group">
          <label htmlFor="agree">
            <input
              type="checkbox"
              id="agree"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              required
            />
            Jeg godtar vilkårene
          </label>
        </div>

        {/* Submit Button */}
        <button type="submit" className="save-button">
          Gå videre
        </button>
      </form>

      {submitted && <p>Form submitted successfully!</p>}
    </div>
  );
};

export default UserDataForm;
