import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";

import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
 
  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });

  return (
      <Navbar color="light" light expand="md" container={false}>
        <Container>
          <NavbarBrand className="logo" />
            <div>
              {process.env.NODE_ENV === 'development' && '(-DEV-)'}
            </div>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink
                  tag={RouterNavLink}
                  to="/"
                >
                  Home
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/profile"
                  >
                    Profile
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLogoutBtn"
                    color="secondary"
                    className="btn-margin"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </Button>
                </NavItem>
              )}
            </Nav>
        </Container>
      </Navbar>
  );
};

export default NavBar;
