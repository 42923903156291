
import ProcessStages from '../components/ProcessStages'
import HouseDataForm from '../components/HouseDataForm'

import './House.css'

export default function House() {
    const stages = ['Kontakinforasjon', 'Bolig og lån', 'Selge andel'];

    return <div>
        <h1>Contact information</h1>
        <ProcessStages stages={stages} currentStage={1} />
        <HouseDataForm />
    </div>
}