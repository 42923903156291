import React from "react";
import './TextInput.css'; // Import the CSS file for styling

const TextInput = ({ label, value, onChange }) => {
  return (
    <div className="input-container">
      <label className={`input-label ${value ? 'shrink' : ''}`}>{label}</label>
      <input
        className="input-field"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type="text"
      />
    </div>
  );
};

export default TextInput;